@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 300;
  src: url('assets/fonts/NotoSansKR-Light.otf') format('woff2'),
    url('assets/fonts/NotoSansKR-Light.otf') format('woff'),
    url('assets/fonts/NotoSansKR-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 400;
  src: url('assets/fonts/NotoSansKR-Regular.otf') format('woff2'),
    url('assets/fonts/NotoSansKR-Regular.otf') format('woff'),
    url('assets/fonts/NotoSansKR-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 500;
  src: url('assets/fonts/NotoSansKR-Medium.otf') format('woff2');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-weight: 700;
  src: url('assets/fonts/NotoSansKR-Bold.otf') format('woff2'),
    url('assets/fonts/NotoSansKR-Bold.otf') format('woff'),
    url('assets/fonts/NotoSansKR-Bold.otf') format('truetype');
}

:root {
  --page-background-color: #ffffff;
  --today-green: rgba(0, 170, 131, 1);
  --page--font-color: #333;
  --page--gray-color: rgba(130, 130, 130, 1);
}

* {
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif !important;
  white-space: nowrap;
}

body {
  padding: 0;
  margin: 0;
}

html {
  background-color: var(--page-background-color);
  color: var(--page--font-color);
  font-size: 20px;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
}

li {
  list-style: none;
}
button {
  border: none;
}
img {
  width: inherit;
  height: inherit;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 16px;
    transition: font-size 0.5s;
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: 12px;
    transition: font-size 0.5s;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 8px;
    transition: font-size 0.5s;
  }
}
